export function pickRand(l: any[]) {
    return l[Math.floor(Math.random() * l.length)];
}

export async function sleep(ms: number): Promise<void> {
    return new Promise(r => setTimeout(r, ms));
}

export function redirect(url: string) {
    if (url.startsWith('http')) {
        window.location.href = url;
    } else {
        if (!url.startsWith('#')) {
            if (!url.startsWith('/')) {
                url = '/' + url;
            }
            url = '#' + url;
        }
        if (process.env.NODE_ENV !== 'production') {
            window.location.href = `http://${window.location.host}/${url}`;
            return;
        }
        window.location.href = `https://${window.location.host}/${url}`;
    }
}

export function formatFromCamelCase(value: string): string {
    return (value.charAt(0).toUpperCase() + value.slice(1)).replace(
        /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g,
        '$1$4 $2$3$5'
    );
}

export function objectsAreSame(objA: any, objB: any): boolean {
    if ((!objA && !!objB) || (!!objA && !objB)) {
        return false;
    }
    const keys: Set<string> = new Set<string>(Object.keys(objA).concat(Object.keys(objB)));
    const iter = keys.keys();
    let val = iter.next();
    while (!val.done) {
        const key = val.value;
        if (objA[key] !== objB[key]) {
            return false;
        }
        val = iter.next();
    }
    return true;
}

/**
 * Await all promises concurrently and map errors to null,
 * @param promises Promises to await
 * @returns Array of results from the promises, null if promise failed
 */
export async function awaitAll(...promises: Promise<any>[]): Promise<any[]> {
    const results = await Promise.all(promises.map(p => p.catch(error => error)));
    return results.map(r => (r instanceof Error ? null : r));
}
