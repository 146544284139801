import React from 'react';
import ItemTable from '../../../components/item_table';
import { TuxView } from '@amzn/tux-static-website';
import { SpaceBetween, Alert, Checkbox } from '@amzn/awsui-components-react';
import AdminRequestHelper from '../../../utils/admin_request_helper';
import { CustomDisplayProps } from '@amzn/tux-static-website/dist/components/item_table';
import AddUserButtonModal from './add_user_button_modal';

interface GroupsViewProps {}

export default class GroupsView extends TuxView<GroupsViewProps, any> {
    private errorMessage?: string;
    private successMessage?: string;
    private groups: string[] = [];
    private isLoading = true;
    private _userGroups: { [key: string]: string[] } = {};
    private customDisplays: CustomDisplayProps = {};
    private updatedGroups: { [key: string]: string[] } = {};

    constructor(props: GroupsViewProps) {
        super(props);
        this.state = {
            showModal: false,
            userAlias: '',
            userEmail: ''
        };
        this.bindAll(this);
        this.loadData();
    }

    async loadData() {
        this.isLoading = true;
        this.dataUpdated();

        const promises: Promise<any>[] = [AdminRequestHelper.groupManagementRequest('/list')];
        const results: any[] = await Promise.all(promises);

        if (results[0].message) {
            this.errorMessage = results[0].message;
        } else {
            this.groups = results[0] as string[];
        }
        this.isLoading = false;
        this.dataUpdated();
    }

    private get hasUpdates(): boolean {
        return Object.keys(this.updatedGroups).length > 0;
    }

    private userGroupUpdated(username: string, groupName: string, isPresent: boolean) {
        console.log(username, groupName, isPresent);
        if (isPresent) {
            // User was added to the group;
            this._userGroups[username].push(groupName);
        } else {
            this._userGroups[username] = this._userGroups[username].filter(g => g !== groupName);
        }

        this.updatedGroups[username] = this.updatedGroups[username] ?? [];

        if (this.updatedGroups[username].includes(groupName)) {
            this.updatedGroups[username] = this.updatedGroups[username].filter(g => g !== groupName);
        } else {
            this.updatedGroups[username].push(groupName);
        }

        if (this.updatedGroups[username].length === 0) {
            delete this.updatedGroups[username];
        }

        this.dataUpdated();
    }

    private get userGroups(): any[] {
        const output: any[] = [];

        for (const group of this.groups) {
            this.customDisplays[group] = (val: boolean, item) => {
                return (
                    <Checkbox
                        checked={val}
                        onChange={({ detail }) => this.userGroupUpdated(item.username, group, detail.checked)}
                    />
                );
            };
        }

        for (const username of Object.keys(this._userGroups)) {
            const user: any = { username: username };

            for (const group of this.groups) {
                user[group] = this._userGroups[username].includes(group);
            }

            output.push(user);
        }

        return output;
    }

    private async savePressed() {
        this.isLoading = true;
        this.dataUpdated();
        if (!this.hasUpdates) {
            this.errorMessage = 'No updates to save';
        }

        const updates: any = {};

        for (const username of Object.keys(this.updatedGroups)) {
            updates[username] = this._userGroups[username];
        }

        const opts: any = {
            body: {
                updates: updates
            }
        };
        if (this.hasUpdates) {
            const result = await AdminRequestHelper.groupManagementRequest<any>('/update', opts);

            if ((result.message as string).toLowerCase().startsWith('success')) {
                this.successMessage = result.message;
            } else {
                this.errorMessage = result.message;
            }
        }

        this.isLoading = false;
        this.dataUpdated();
    }

    private async addUserPressed() {
        this.setState({ showModal: true });
        this.dataUpdated();
    }
    handleModalClose(): void {
        this.setState({ showModal: false });
        this.dataUpdated();
    }
    handleSelectedUsers(users: any[]): void {
        for (const key of users) {
            const groupList: string[] = [];
            for (const group of key.userGroups) {
                groupList.push(group.GroupName);
            }
            this._userGroups[key.username] = groupList;
        }
        this.setState({ showModal: false });
        this.setState({ users: [] });
        this.dataUpdated();
    }

    render() {
        return (
            <SpaceBetween size="s">
                {this.errorMessage ? (
                    <Alert
                        type="error"
                        header={this.errorMessage}
                        visible={!!this.errorMessage}
                        onDismiss={e => {
                            this.errorMessage = undefined;
                            this.dataUpdated();
                        }}
                        dismissible
                    />
                ) : null}
                {this.successMessage ? (
                    <Alert
                        type="success"
                        header={this.successMessage}
                        visible={!!this.successMessage}
                        onDismiss={() => {
                            this.successMessage = undefined;
                            this.dataUpdated();
                        }}
                    />
                ) : null}
                <ItemTable
                    title="Users"
                    items={this.userGroups}
                    isLoading={this.isLoading}
                    initialColumnOrder={[
                        'username',
                        'Module_Regular_User_NA',
                        'Module_Regular_User_EU',
                        'Site_Regular_User',
                        'External_Site_User',
                        'External_Module_User_NA',
                        'External_Module_User_EU',
                        'AdminAccess'
                    ]}
                    customDisplays={this.customDisplays}
                    customWidths={{
                        username: 250,
                        Module_Regular_User_NA: 230,
                        Module_Regular_User_EU: 230,
                        Site_Regular_User: 200,
                        External_Site_User: 180,
                        External_Module_User_NA: 240,
                        External_Module_User_EU: 240,
                        AdminAccess: 150
                    }}
                    actions={[
                        { label: 'Save', primary: true, callback: this.savePressed },
                        { label: 'Add User', primary: true, callback: this.addUserPressed }
                    ]}
                    disableSelection
                />
                <AddUserButtonModal
                    isOpen={this.state.showModal}
                    callBackForModalClose={this.handleModalClose}
                    callBackForSearchResult={this.handleSelectedUsers}
                />
            </SpaceBetween>
        );
    }
}
