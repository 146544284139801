import React from 'react';
import { NoLoadTabs, TuxView } from '@amzn/tux-static-website';
import { Header, Spinner } from '@amzn/awsui-components-react';
import { RouteComponentProps } from 'react-router';
import NewUser from './tabs/new_user';
import GroupsView from './tabs/groups';
import AnalyticsView from './tabs/analytics';

type AdminViewProps = RouteComponentProps<{ [x: string]: string | undefined }, any, any>;

export default class AdminView extends TuxView<AdminViewProps, any> {
    isLoading = true;
    initialTab?: string;

    constructor(props: AdminViewProps) {
        super(props);
        this.bindAll(this);
        this.loadData();
    }

    async loadData() {
        // if (!UserInfoHelper.isAdmin) {
        //     redirect("/error");
        // }
        this.initialTab = this.props.match.params.tab;
        this.isLoading = false;
        this.dataUpdated();
    }

    get tabs() {
        return [
            { id: 'new_user', label: 'New User', content: <NewUser /> },
            { id: 'users', label: 'Users', content: <GroupsView /> },
            { id: 'analytics', label: 'Analytics', content: <AnalyticsView /> }
        ];
    }

    render() {
        // if (!UserInfoHelper.isAdmin) {
        //     redirect("/error");
        // }
        if (this.isLoading) {
            return <Spinner size="big" />;
        }
        return (
            <>
                <Header variant="h1">Admin Actions</Header>
                <NoLoadTabs tabs={this.tabs} initialTab={this.initialTab} updateHash />
            </>
        );
    }
}
