import { AppLayout } from '@amzn/awsui-components-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import PersistentHeader from './components/frame/header';
import { PersistentHeader as StaticHeader } from '@amzn/tux-static-website';
import LeftNav from './components/frame/left_nav';
import Routing from './routing';
import { SessionHelper, UserInfoHelper } from '@amzn/tux-static-website';
import MeridianAppLayout from '@amzn/meridian/app-layout';

interface BaseAppProps {}

export const appLayoutID = 'app-layout';

export default class BaseApp extends React.Component<BaseAppProps, any> {
    navigationOpen = true;

    render(): JSX.Element {
        return (
            <div id="base_app" style={{ visibility: 'hidden' }}>
                <MeridianAppLayout
                    headerComponent={StaticHeader}
                    sidebarComponent={LeftNav}
                    spacingInset="400"
                    alwaysShowScrollbar
                >
                    <StaticHeader />
                    <LeftNav />
                    <div id={appLayoutID} className="awsui">
                        <Routing />
                    </div>
                </MeridianAppLayout>
            </div>
        );
    }
}

SessionHelper.init();
UserInfoHelper.loadUserInfoFromCookies();

const rootElement: Element = document.getElementById('root')!;
const root = ReactDOM.createRoot(rootElement);
root.render(<BaseApp />);
