import React from 'react';
import { TuxComponent } from '@amzn/tux-static-website';
import {
    Alert,
    Button,
    ButtonDropdown,
    ButtonDropdownProps,
    ColumnLayout,
    Container,
    FormField,
    Header,
    Input,
    NonCancelableCustomEvent,
    SpaceBetween,
    Spinner,
    TokenGroup,
    TokenGroupProps
} from '@amzn/awsui-components-react';
import { BaseChangeDetail } from '@amzn/awsui-components-react/polaris/input/interfaces';
import AdminRequestHelper from '../../../utils/admin_request_helper';

interface NewUserProps {}

export default class NewUser extends TuxComponent<NewUserProps, any> {
    isLoading = true;
    givenName = '';
    familyName = '';
    email = '';
    username = '';

    givenNameError?: string;
    familyNameError?: string;
    emailError?: string;
    usernameError?: string;

    canSubmit = false;

    isSubmitting = false;
    errorMessage?: string;
    successMessage?: string;
    availableGroups: string[] = [];
    groups: string[] = [];

    constructor(props: NewUserProps) {
        super(props);
        this.bindAll(this);
        this.loadData();
    }

    async loadData() {
        this.isLoading = true;
        this.dataUpdated();

        const groupResults = await AdminRequestHelper.groupManagementRequest<any>('/list');
        if (groupResults.message) {
            this.errorMessage = groupResults.message;
        } else {
            this.availableGroups = (groupResults as string[]).filter(
                g => !g.includes('AmazonEmployee') && !g.toLowerCase().includes('admin')
            );
        }

        this.isLoading = false;
        this.dataUpdated();
    }

    get isFormFilled(): boolean {
        return this.givenName.length > 0 && this.familyName.length > 0 && this.email.length > 0;
    }

    clearFields() {
        this.givenName = '';
        this.familyName = '';
        this.email = '';
        this.username = '';
        this.canSubmit = true;
        this.givenNameError = undefined;
        this.familyNameError = undefined;
        this.emailError = undefined;
    }

    validate() {
        this.canSubmit = true;
        this.givenNameError = undefined;
        this.familyNameError = undefined;
        this.emailError = undefined;
        this.usernameError = undefined;
        this.givenName = this.givenName.trim();
        this.familyName = this.familyName.trim();
        this.email = this.email.trim();
        this.username = this.username.trim();

        if (this.givenName.length === 0) {
            this.canSubmit = false;
            this.givenNameError = 'Given name is required';
        }

        if (this.familyName.length === 0) {
            this.canSubmit = false;
            this.givenNameError = 'Family name is required';
        }

        if (this.email.length === 0 || !this.validateEmail(this.email)) {
            this.canSubmit = false;
            this.emailError = 'A valid email address is required';
        }

        if (this.username.length > 0 && this.username.length < 4) {
            this.canSubmit = false;
            this.usernameError = 'Username must be at least 4 characters long';
        }
    }

    givenNameChanged(e: NonCancelableCustomEvent<BaseChangeDetail>) {
        this.givenName = e.detail.value;
        this.dataUpdated();
    }

    familyNameChanged(e: NonCancelableCustomEvent<BaseChangeDetail>) {
        this.familyName = e.detail.value;
        this.dataUpdated();
    }

    emailChanged(e: NonCancelableCustomEvent<BaseChangeDetail>) {
        this.email = e.detail.value;
        this.dataUpdated();
    }

    usernameChanged(e: NonCancelableCustomEvent<BaseChangeDetail>) {
        this.username = e.detail.value;
        this.dataUpdated();
    }

    validateEmail(email: string) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    async submitPressed() {
        if (this.isSubmitting) {
            return;
        }
        this.validate();
        if (!this.canSubmit) {
            this.dataUpdated();
            return;
        }
        this.isSubmitting = true;
        this.dataUpdated();

        const opts: { [key: string]: any } = {
            method: 'post',
            body: {
                email: this.email,
                given_name: this.givenName,
                family_name: this.familyName
            }
        };

        if (this.username.length > 0) {
            opts.body['username'] = this.username;
        }

        let result: any = await AdminRequestHelper.userManagementRequest('/create-external', opts);
        console.log('RESULT: ', result);
        if (result?.message) {
            this.errorMessage = result.message;
        } else if (result.User && this.groups.length > 0) {
            const newUser = result.User;
            const username = newUser.Username;
            const updates: any = {};
            updates[username] = this.groups;
            result = await AdminRequestHelper.groupManagementRequest('/update', {
                body: { updates: updates }
            });
            if (result.message !== 'Success') {
                this.errorMessage =
                    (this.errorMessage ?? '') +
                    (this.errorMessage ? ', ' : '') +
                    'Failed adding to groups: ' +
                    result.message;
            }
        }

        if (!this.errorMessage) {
            this.successMessage = 'Success!';
            this.groups = [];
        }

        if (result) {
            console.log(result);
        }

        this.clearFields();
        this.isSubmitting = false;
        this.dataUpdated();
    }

    getActions() {
        if (this.isSubmitting) {
            return (
                <Button disabled>
                    <Spinner />
                    &nbsp;Loading...
                </Button>
            );
        }

        return (
            <Button variant="primary" disabled={!this.isFormFilled} onClick={this.submitPressed}>
                Submit
            </Button>
        );
    }

    groupPressed(e: NonCancelableCustomEvent<ButtonDropdownProps.ItemClickDetails>) {
        const groupId = e.detail.id;
        this.groups.push(groupId);
        this.dataUpdated();
    }

    groupDismissed(e: NonCancelableCustomEvent<TokenGroupProps.DismissDetail>) {
        const group = this.groups[e.detail.itemIndex];
        this.groups = this.groups.filter(g => g !== group);
        this.dataUpdated();
    }

    getGroupItems(): ButtonDropdownProps.ItemOrGroup[] {
        return this.availableGroups
            .filter(g => !this.groups.includes(g))
            .map(g => ({
                id: g,
                text: g
            }));
    }

    getGroupTokens(): TokenGroupProps.Item[] {
        return this.groups.map(g => ({
            label: g
        }));
    }

    render() {
        return (
            <SpaceBetween size="s">
                {this.errorMessage ? (
                    <Alert type="error" header={this.errorMessage} visible={!!this.errorMessage} />
                ) : null}
                {this.successMessage ? (
                    <Alert
                        type="success"
                        header={this.successMessage}
                        visible={!!this.successMessage}
                        onDismiss={() => {
                            this.successMessage = undefined;
                            this.dataUpdated();
                        }}
                    />
                ) : null}
                <Container header={<Header actions={this.getActions()}>New User Creation Form</Header>}>
                    <ColumnLayout columns={2} variant="text-grid">
                        <SpaceBetween size="m">
                            <FormField label="Given Name" errorText={this.givenNameError}>
                                <Input type="text" value={this.givenName} onChange={this.givenNameChanged} />
                            </FormField>
                            <FormField label="Family Name" errorText={this.familyNameError}>
                                <Input type="text" value={this.familyName} onChange={this.familyNameChanged} />
                            </FormField>
                            <FormField
                                label="Username"
                                errorText={this.usernameError}
                                description="*Optional, one will be generated if left blank"
                            >
                                <Input type="text" value={this.username} onChange={this.usernameChanged} />
                            </FormField>
                            <FormField
                                label="Email Address"
                                errorText={this.emailError}
                                description="Full email address (Ex: example123@example.com)"
                            >
                                <Input type="text" value={this.email} onChange={this.emailChanged} />
                            </FormField>
                        </SpaceBetween>
                        <SpaceBetween size="m">
                            <FormField label="Groups" errorText={this.givenNameError}>
                                <ButtonDropdown onItemClick={this.groupPressed} items={this.getGroupItems()}>
                                    {this.isLoading ? 'Loading...' : 'Select Group(s)'}
                                </ButtonDropdown>
                                <TokenGroup items={this.getGroupTokens()} onDismiss={this.groupDismissed} />
                            </FormField>
                        </SpaceBetween>
                    </ColumnLayout>
                </Container>
            </SpaceBetween>
        );
    }
}
