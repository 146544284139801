import React from 'react';
import { TuxComponent } from '@amzn/tux-static-website';
import {
    ExpandableSection,
    ExpandableSectionProps,
    Spinner,
    NonCancelableCustomEvent,
    Header,
    Button,
    ButtonProps
} from '@amzn/awsui-components-react';

export interface DelayedLoadExpandableProps<T> {
    loadData: () => Promise<T>;
    content: (val: T) => React.ReactNode;
    title?: string;
    variant?: ExpandableSectionProps.Variant;
}

export default class DelayedLoadExpandable<T> extends TuxComponent<DelayedLoadExpandableProps<T>, any> {
    private isExpanded = false;
    private hasBeenExpanded = false;
    private data: any;

    constructor(props: DelayedLoadExpandableProps<T>) {
        super(props);
        this.bindAll(this);
        this.loadData();
    }

    async loadData() {
        // console.log("In loadData");
    }

    async refresh(e: CustomEvent<ButtonProps.ClickDetail>) {
        e.stopPropagation();

        this.hasBeenExpanded = false;
        this.dataUpdated();

        this.data = await this.props.loadData();

        this.hasBeenExpanded = true;
        this.dataUpdated();
    }

    async onExpansionChange(event: NonCancelableCustomEvent<ExpandableSectionProps.ChangeDetail>) {
        if (!this.hasBeenExpanded) {
            this.hasBeenExpanded = true;
            this.data = await this.props.loadData();
            this.dataUpdated();
        }
        this.isExpanded = event.detail.expanded;
    }

    render() {
        return (
            <ExpandableSection
                defaultExpanded={false}
                variant={this.props.variant}
                header={
                    <Header actions={<Button iconName="refresh" onClick={this.refresh}></Button>}>
                        {this.props.title ?? ''}
                    </Header>
                }
                onChange={this.onExpansionChange}
            >
                {this.hasBeenExpanded ? this.props.content(this.data) : <Spinner size="big" />}
            </ExpandableSection>
        );
    }
}
