import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { Routing as StaticRouting, BreadCrumbRoute } from '@amzn/tux-static-website';
import AdminView from './pages/admin/admin_view';
import NoAdminContent from './components/no_admin_content';
import TestView from './pages/test_view';

export { BreadCrumbRoute };

interface RoutingProps {}

export default class Routing extends React.Component<RoutingProps, any> {
    render(): JSX.Element {
        return (
            <>
                <Router>
                    <Switch>
                        {' '}
                        {/*NOTE: Order matters, /test if before /:region to test won't be treated as a region */}
                        <BreadCrumbRoute name="Test" path="/:region?/test" component={TestView} />
                        <BreadCrumbRoute
                            name="Admin"
                            path="/:region?/admin/:tab?"
                            component={AdminView}
                            eventsDisabled
                        />
                        <Route path="*" component={NoAdminContent} />
                    </Switch>
                </Router>

                <StaticRouting hideError />
            </>
        );
    }
}
