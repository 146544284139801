/**
 * Safe alternative to localStorage, doesn't throw error when access is denied
 */
export default class StorageHelper {
    /** True if localStorage is enabled or no attempts to check have been made, false otherwise */
    private static isEnabled = true;

    private constructor() {}

    /**
     * Set an item in localStorage, if enabled
     * @param key Key to assign a value for
     * @param value Value to be stored
     */
    public static setString(key: string, value: string) {
        if (key == null || value == null) {
            return;
        }
        try {
            localStorage.setItem(key, value);
        } catch (err) {
            StorageHelper.isEnabled = false;
            console.error(err);
        }
    }

    /**
     * Set an item in localStorage, if enabled
     * @param key Key to assign a value for
     * @param value Value to be stored
     */
    public static setObject(key: string, value: any) {
        if (key == null || value == null) {
            return;
        }
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (err) {
            StorageHelper.isEnabled = false;
            console.error(err);
        }
    }

    /**
     * Retrieve a value from local storage
     * @param key Key to retrieve the value for
     * @param defaultValue Default value to return if key is missing, default: null
     * @returns Value stored for key, or ```defaultValue``` if missing
     */
    public static getString(key: string, defaultValue?: string | null): string | null {
        if (defaultValue === undefined) {
            defaultValue = null;
        }
        if (key == null) {
            return defaultValue;
        }
        let value: string | null = null;

        try {
            value = localStorage.getItem(key);
        } catch (err) {
            StorageHelper.isEnabled = false;
            console.error(err);
        }

        return value || defaultValue;
    }

    /**
     * Retrieve a value from local storage
     * @param key Key to retrieve the value for
     * @param defaultValue Default value to return if key is missing, default: null
     * @returns Value stored for key, or ```defaultValue``` if missing
     */
    public static getObject<P>(key: string, defaultValue?: P | null): P | null {
        if (defaultValue === undefined) {
            defaultValue = null;
        }
        if (key == null) {
            return defaultValue;
        }
        let value: P | null = null;

        try {
            const strValue = localStorage.getItem(key);
            if (strValue) {
                value = JSON.parse(strValue) as P;
            }
        } catch (err) {
            StorageHelper.isEnabled = false;
            console.error(err);
        }

        return value || defaultValue;
    }
}
